exports.components = {
  "component---src-components-page-markdown-js": () => import("./../../../src/components/page-markdown.js" /* webpackChunkName: "component---src-components-page-markdown-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-android-js": () => import("./../../../src/pages/download/android.js" /* webpackChunkName: "component---src-pages-download-android-js" */),
  "component---src-pages-download-cordova-js": () => import("./../../../src/pages/download/cordova.js" /* webpackChunkName: "component---src-pages-download-cordova-js" */),
  "component---src-pages-download-flutter-js": () => import("./../../../src/pages/download/flutter.js" /* webpackChunkName: "component---src-pages-download-flutter-js" */),
  "component---src-pages-download-index-js": () => import("./../../../src/pages/download/index.js" /* webpackChunkName: "component---src-pages-download-index-js" */),
  "component---src-pages-download-ios-js": () => import("./../../../src/pages/download/ios.js" /* webpackChunkName: "component---src-pages-download-ios-js" */),
  "component---src-pages-download-react-native-js": () => import("./../../../src/pages/download/react-native.js" /* webpackChunkName: "component---src-pages-download-react-native-js" */),
  "component---src-pages-download-web-js": () => import("./../../../src/pages/download/web.js" /* webpackChunkName: "component---src-pages-download-web-js" */),
  "component---src-pages-download-windows-js": () => import("./../../../src/pages/download/windows.js" /* webpackChunkName: "component---src-pages-download-windows-js" */),
  "component---src-pages-openapi-js": () => import("./../../../src/pages/openapi.js" /* webpackChunkName: "component---src-pages-openapi-js" */)
}

